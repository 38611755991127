"use client";

import React from "react";
import Link from "next/link";

export default function SearchButton({
  org,
  repo,
}: {
  org: string;
  repo: string;
}) {
  return (
    <Link
      className="cursor-pointer hover:bg-blue-100/25 rounded-md shadow-md p-2"
      key={org + repo}
      href={`/browse/${org}/${repo}/index.md`}>
      {org}/{repo}
    </Link>
  );
}
